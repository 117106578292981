import { IconButton } from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";

function DownloadImageButton({ imageUrl }) {
  const downloadImage = () => {
    saveAs(imageUrl, "image.jpg");
  };

  return (
    <IconButton onClick={downloadImage}>
      <DownloadIcon />
    </IconButton>
  );
}

export default DownloadImageButton;
