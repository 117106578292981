import { encodingForModel } from "js-tiktoken";

// export const gpt4Preview = "gpt-4-0125-preview";
// export const gpt35Turbo = "gpt-3.5-turbo-0125";
// export const gpt4 = "gpt-4";
// export const gpt4Vision = "gpt-4-vision-preview";

export const dalle3 = "dall-e-3";
export const gpt4o = "gpt-4o";
export const gpt4oMini = "gpt-4o-mini";
export const o1Preview = "o1-preview";
export const o1Mini = "o1-mini";

export const gemini15flash = "gemini-1.5-flash";
export const gemini15flash8b = "gemini-1.5-flash-8b";
export const gemini15pro = "gemini-1.5-pro";

export const textModels = [gpt4o, gpt4oMini, o1Preview, o1Mini, gemini15flash8b, gemini15flash, gemini15pro];

export const newBotModels = [
  dalle3,
  gpt4o,
  gpt4oMini,
  o1Preview,
  o1Mini,
  gemini15flash8b,
  gemini15flash,
  gemini15pro,
];

// // @ TODO: This is shared between frontend and backend
// export const charges = {
//   textInput: 0.01,
//   textOutput: 0.01,
//   imageInput: 0.05,
//   imageOutput: 0.05,
//   streamToken: 0.00001,
// }

export const modelMeta = {
  [gpt4o]: {
    inputType: "text",
    outputType: "text",
    type: "text",
  },
  [gpt4oMini]: {
    inputType: "text",
    outputType: "text",
    type: "text",
  },
  [o1Preview]: {
    inputType: "text",
    outputType: "text",
    type: "text",
  },
  [o1Mini]: {
    inputType: "text",
    outputType: "text",
    type: "text",
  },
  [dalle3]: {
    inputType: "text",
    outputType: "image",
    type: "image",
  },
  [gemini15flash8b]: {
    inputType: "text",
    outputType: "text",
    type: "text",
  },
  [gemini15flash]: {
    inputType: "text",
    outputType: "text",
    type: "text",
  },
  [gemini15pro]: {
    inputType: "text",
    outputType: "text",
    type: "text",
  },
};

// export const modelCosts = {
//   [gpt4o]: {
//     input: 5.0 / 1000000.0,
//     output: 15.0 / 1000000.0,
//   },
//   [gpt4Preview]: {
//     input: 10.0 / 1000000.0,
//     output: 30.0 / 1000000.0,
//   },
//   [gpt35Turbo]: {
//     input: 0.5 / 1000000.0,
//     output: 1.5 / 1000000.0,
//   },
//   [gpt4]: {
//     input: 30.0 / 1000000.0,
//     output: 60.0 / 1000000.0,
//   },
//   [dalle3]: {
//     price: 0.04,
//   },
// };

// export const encoders = {};

// textModels.forEach((model) => {
//   if(model === gpt4Vision || model === gpt4o) {
//     encoders[model] = encodingForModel(gpt4Preview);
//   } else {
//     encoders[model] = encodingForModel(model);
//   }
// });

// export const estimateContextCost = (bot, contextMessages) => {
//   const costs = modelMeta[bot.model].costs;
//   if (bot.type === "image") return 0.0;
//   let total = 0;
//   contextMessages.forEach((message) => {
//     const tokenCount = encoders[bot.model].encode(message.content).length;
//     total += tokenCount * costs.input;
//   });
//   return total;
// };

// export const estimatePromptCost = (bot, prompt) => {
//   if (bot.type === "image") return 0.0;
//   const tokenCount = encoders[bot.model].encode(prompt).length;
//   return (
//     tokenCount * modelMeta[bot.model].costs.input
//   );
// };
