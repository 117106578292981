import React, { useState } from 'react';
import { TextField, Button, Typography, Stack } from '@mui/material';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "firebaseApp";
import AlertMessage from '../generic-ui/AlertMessage';
import { getFriendlyAuthErrorMessage } from 'utils/react-lib/auth/messages';

export const MIN_8_CHARS_PASSWORD = {
  regex: /^[A-Za-z\d@$!%*?&]{8,}$/,
  message: "The password must have at least 8 characters",
}

export const STRONG_PASSWORD = {
  regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  message: "Password must be at least 8 characters long, include uppercase and lowercase letters, a number, and a special character.",
}

function EmailPasswordAuthForm({
  passwordConstraint = MIN_8_CHARS_PASSWORD,
}) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return passwordConstraint.regex.test(password);
  };

  const handleAuth = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setError("Invalid email.");
      return;
    }

    if (!validatePassword(password)) {
      setError(passwordConstraint.message);
      return;
    }

    setError("");

    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (signInError) {
      console.log(signInError.code);
      switch(signInError.code) {
        case "auth/wrong-password":
          setError(getFriendlyAuthErrorMessage(signInError));
        break;
        default:
          try {
            await createUserWithEmailAndPassword(auth, email, password);
          } catch (signupError) {
            console.log(signupError);
            setError(getFriendlyAuthErrorMessage(signupError));
          }
        break;
      }
    }
  };

  return (
    <form onSubmit={handleAuth}>
      <Stack spacing={2}>
        <Typography>Login or Register</Typography>
        <TextField
          data-testid="email-input"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <TextField
          data-testid="password-input"
          type="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />
        {error && (
          <AlertMessage severity="error">
            {error}
          </AlertMessage>
        )}
        <Button
          data-testid="login-submit-button"
          variant="contained"
          color="primary"
          type="submit"
        >
          Submit
        </Button>
      </Stack>
    </form>
  );
}

export default EmailPasswordAuthForm;