import React from "react";
import { useSelector } from "react-redux";
import { buildTypingIndicatorMessage } from "utils/chat";
import TypingIndicator from "./TypingIndicator";
import MessageContainer from "./MessageContainer";

export default function TypingMessage({message, inContext}) {

  const botState = useSelector((state) => state.bot);
  
  return (
    <MessageContainer
      message={message}
      inContext={inContext}
      noMenu={true}
    >
      <TypingIndicator />
    </MessageContainer>
  );

}
