import { auth, db } from "firebaseApp";
import { addDoc, collection, query, setDoc, where } from "firebase/firestore";
import React, { useEffect } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useDispatch, useSelector } from "react-redux";
import { botActions } from "store/bot";
import { gpt4Preview, gpt4Vision, gpt4o } from "utils/modelUtils";
import { COLLECTIONS } from "collections";

export default function BotLoader() {

  const uid = auth.currentUser.uid;
  
  const dispatch = useDispatch();
  
  const q = query(
    collection(db, COLLECTIONS.bots),
    where("uid", "==", uid),
  );

  const [snapshot, loading, error] = useCollection(q);

  if(error) {
    console.log(error);
  }

  const createDefaultBots = async () => {
    await addDoc(collection(db, COLLECTIONS.bots), {
      uid,
      name: "GPT-4o",
      model: gpt4o,
      type: "text",
      avatar: "Callie",
      systemPrompt: "You are a helpful assistant.",
      contextSize: 1,
      createdAt: new Date().getTime(),
      stream: false,
      fileId: null,
    });
    await addDoc(collection(db, COLLECTIONS.bots), {
      uid,
      name: "DALL·E 3",
      model: "dall-e-3",
      type: "image",
      avatar: "Toby",
      systemPrompt: "",
      contextSize: 0,
      createdAt: new Date().getTime() + 1,
      fileId: null,
    });
  }

  useEffect(() => {
    if(snapshot) {
      const bots = snapshot.docs.map((doc) => ({...doc.data(), id: doc.id}));
      if(bots.length === 0) {
        createDefaultBots();
      } else {
        dispatch(botActions.initialize(bots.sort((x, y) => x.createdAt - y.createdAt)));
      }
    }
  }, [snapshot]);

  return null;

}
