// @lib-generic-ui
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

export default function ConfirmModal({ open, setOpen, title, message, confirmAction, cancelAction }) {

  const handleConfirm = () => {
    if(confirmAction) {
      confirmAction();
    }
    setOpen(false);
  }

  const handleCancel = () => {
    if(cancelAction) {
      cancelAction();
    }
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        {title || "Confirm"}
      </DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <ButtonGroup
          fullWidth
        >
          <Button
            variant="contained"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleConfirm}
            data-testid="confirm-modal-confirm-button"
          >
            Confirm
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );

}
