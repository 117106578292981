// @lib-firebase-auth
import React, { useState } from "react";
import {
  Alert,
  Stack,
  Button,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  auth,
} from "firebaseApp";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { useAuth } from "context/react-lib/auth/AuthProvider";

export default function GoogleLoginButton({
  loginProps = {},
  logoutProps = {},
}) {

  const { currentUser } = useAuth();

  const googleAuthProvider = new GoogleAuthProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const loginWithGoogle = async () => {
    setError();
    setIsLoading(true);
    try {
      await signInWithPopup(auth, googleAuthProvider);
    } catch (error) {
      if(error.code !== "auth/popup-closed-by-user") {
        setError("Could not login with Google.")
      }
    }
    setIsLoading(false);
  }

  const logout = async () => {
    await signOut(auth);
  }

  const renderButton = () => {
    if (currentUser) {
      return (
        <Button
          {...logoutProps}
          startIcon={<ExitToAppIcon />}
          onClick={logout}
          disabled={isLoading}
        >
          Logout
        </Button>
      );
    }
    return (
      <Button
        data-testid="google-login-button"
        {...loginProps}
        startIcon={<GoogleIcon />}
        onClick={loginWithGoogle}
        disabled={isLoading}
      >
        login with google
      </Button>
    );
  }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
    >
      {error && <Alert severity="error">{error}</Alert>}
      {renderButton()}
    </Stack>
  )
}
