import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { colors } from "colors";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { botActions } from "store/bot";

export default function ContextSizeSelector() {
  const options = [0, 1, 2, 3, 4, 5];

  const dispatch = useDispatch();
  const contextSize = useSelector((state) => state.bot.contextSize);

  return (
    <FormControl
      sx={{
        width: "80px",
        //backgroundColor: "#9290C3",
        borderRadius: 1,
      }}
    >
      <Stack alignItems="center" justifyContent="center">
        <Tooltip
          title="Number of previous messages considered when answering."
          placement="top"
        >
          <Typography
            fontSize={10}
            sx={{ color: colors.white }}
            textAlign="center"
          >
            context
          </Typography>
        </Tooltip>
        <Select
          size="small"
          value={contextSize}
          onChange={(event) =>
            dispatch(botActions.setContextSize(event.target.value))
          }
          sx={{
            color: colors.white,
            backgroundColor: colors.drakGray,
            "& .MuiSelect-select": {
              backgroundColor: colors.drakGray,
            },
            ".MuiSelect-icon": {
              color: colors.white,
            },
            ".MuiInputLabel-root": {
              color: colors.white,
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </FormControl>
  );
}
