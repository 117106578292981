import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";

export default function UserInGroupMenu() {

  return (
    <IconButton aria-label="user">
      <MoreVertIcon />
    </IconButton>
  );

}
