import ScrollableCollection from "components/react-lib/generic-ui/ScrollableCollection";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import { collection, query, where } from "firebase/firestore";
import { db } from "firebaseApp";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import ChatInput from "components/chat/ChatInput";
import Message from "components/chat/Message";
import { buildGreetingMessage } from "utils/chat";
import { COLLECTIONS } from "collections";
import { colors } from "colors";

export default function Conversation() {

  const auth = useAuth();
  const uid = auth.currentUser?.uid;
  const botState = useSelector((state) => state.bot);
  const bot = botState.idMap[botState.current];
  const contextSize = botState.contextSize;

  const [messageQuery, setMessageQuery] = useState(null);

  useEffect(() => {
    if (uid && botState.current) {
      const q = botState.activeConversation ? query(
        collection(
          db,
          COLLECTIONS.bots,
          botState.current,
          COLLECTIONS.conversations,
          botState.activeConversation.id,
          COLLECTIONS.messages
        ),
        where("cancelled", "==", false),
      ) : null;
      setMessageQuery(q);
    }
  }, [botState.current, botState.activeConversation, uid]);

  const renderMessage = (message, indexFromStart, indexFromEnd) => {
    return <Message message={message} inContext={indexFromEnd + 1 <= 2 * contextSize} />;
  };

  const renderPrefixElements = () => {
    return (
      <Typography
        sx={{color: colors.white}}
      >
        This is the start of this conversation.
      </Typography>
    );
  };

  const renderSuffixElements = () => {
    return null;
  };

  const renderMessages = () => {
    if (messageQuery) {
      return (
        <ScrollableCollection
          collectionQuery={messageQuery}
          renderElement={renderMessage}
          renderPrefixElements={renderPrefixElements}
          renderSuffixElements={renderSuffixElements}
          containerStyle={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        />
      );
    } else {
      return (
        <div
          style={{
            flexGrow: 1,
          }}
        />
      );
    }
  };

  return (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="center"
      sx={{
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
      }}
      px={3}
      py={2}
    >
      {renderMessages()}
      <ChatInput
        placeholder="How can I help you?..."
        sx={{
          zIndex: 3,
          width: "100%",
        }}
      />
    </Stack>
  );
}
