import "./App.css";
import ChatView from "./views/ChatView";
import AuthView from "./views/AuthView";
import { Route, Routes } from "react-router-dom";
import PaymentRedirectView from "views/PaymentRedirectView";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import BotLoader from "components/bot/BotLoader";
import TestView from "views/TestView";
import ToolsView from "views/ToolsView";

function App() {

  const auth = useAuth();
  
  if(!auth.currentUser) {
    return <AuthView />;
  }

  return (
    <>
      <BotLoader />
      <Routes>
        <Route path="/" element={<ChatView />} />
        <Route path="/payment/:status" element={<PaymentRedirectView />} />
        <Route path="/test" element={<TestView />} />
        <Route path="/tools" element={<ToolsView />} />
      </Routes>
    </>
  );
}

export default App;
