import { Button, Stack, TextField } from "@mui/material";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import React, { useState } from "react";

export default function YouTube() {
  const [videoUrl, setVideoUrl] = useState("https://www.youtube.com/watch?v=b_fyVr0ZqP0");
  const [processing, setProcessing] = useState(false);

  const processVideo = async () => {
    try {
      setProcessing(true);
      const res = await httpsCallable(
        functions,
        "downloadYoutubeAudio"
      )({
        videoUrl,
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Stack>
      <TextField
        value={videoUrl}
        onChange={(event) => setVideoUrl(event.target.value)}
      />
      <ActionButton
        processing={processing}
        variant="contained"
        onClick={processVideo}
      >
        Process
      </ActionButton>
    </Stack>
  );
}
