import { AVATAR_FIELD, STRING_FIELD } from "utils/react-lib/generic-ui/firestoreFields";

export const userPublicProfilesSchema =  {
  
  "userPublicProfiles/{id}": (buildData) => ({
    _fieldOrder: ["avatarUrl", "username"],
    username: STRING_FIELD("Username"),
    avatarUrl: AVATAR_FIELD("Avatar", buildData),
  }),

}