import {
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { botActions } from "store/bot";
import { useDispatch, useSelector } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import { createBotActions } from "store/createBot";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountModal from "../account/AccountModal";
import MenuIcon from '@mui/icons-material/Menu';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsModal from "components/groups/GroupsModal";
import CreateBotModal from "components/bot/CreateBotModal";
import BotAvatar from "components/bot/BotAvatar";
import EditProfileModal from "components/users/EditProfileModal";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LogoutButton from "components/react-lib/auth/LogoutButton";

export default function MainMenu() {

  const dispatch = useDispatch();
  const botState = useSelector((state) => state.bot);

  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [groupsModalOpen, setGroupsModalOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);

  const bots = botState.bots;

  const currentBot = botState.current;

  const handleSelectBot = (botId) => {
    dispatch(botActions.selectBot(botId));
    setMenuOpen(false);
  };

  const handleCreateBot = () => {
    dispatch(createBotActions.reset());
    setMenuOpen(false);
    setModalOpen(true);
  };

  const handleEdit = (event, bot) => {
    event.stopPropagation();
    dispatch(createBotActions.initializeFromBot(bot));
    setMenuOpen(false);
    setModalOpen(true);
  };

  const handleEditProfile = () => {
    setMenuOpen(false);
    setProfileModalOpen(true);
  }

  const handleEditGroups = () => {
    setMenuOpen(false);
    setGroupsModalOpen(true);
  }

  const formatName = (name) => {
    if (name.length > 24) {
      return `${name.substring(0, 24)}...`;
    }
    return name;
  };

  return (
    <>
      <IconButton 
        onClick={() => setMenuOpen(true)} 
        color="white"
        data-testid="main-menu-button"
      >
        <MenuIcon />
      </IconButton>
      <CreateBotModal open={modalOpen} setOpen={setModalOpen} />
      <AccountModal open={profileModalOpen} setOpen={setProfileModalOpen} />
      <GroupsModal open={groupsModalOpen} setOpen={setGroupsModalOpen} />
      <EditProfileModal 
        open={userModalOpen} 
        setOpen={setUserModalOpen}
      />
      <Drawer 
        data-testid="main-menu-drawer"
        open={menuOpen} 
        anchor="left" 
        onClose={() => setMenuOpen(false)}
      >
        <MenuList
          sx={{
            overflowY: "auto",
          }}
        >
          <MenuItem>
          <LogoutButton 
            fullWidth
            variant="outlined"
          />
          </MenuItem>
          
          <MenuItem onClick={() => setUserModalOpen(true)} data-testid="profile-menu-item">
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleEditProfile} data-testid="credit-accounts-menu-item">
            <ListItemIcon>
              <AccountBalanceWalletIcon />
            </ListItemIcon>
            <ListItemText>Credit Accounts</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleEditGroups} data-testid="groups-menu-item">
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText>Groups</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleCreateBot} data-testid="create-bot-menu-item">
            <ListItemIcon>
              <AddCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText>Create Bot</ListItemText>
          </MenuItem>
          <Divider />
          {bots.map((bot) => (
            <MenuItem
              data-testclass="main-menu-bot-item"
              key={bot.id}
              onClick={() => handleSelectBot(bot.id)}
              selected={bot.id === currentBot}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                width="100%"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  pl={2}
                  sx={{
                    cursor: "pointer",
                  }}
                  spacing={1}
                >
                  <BotAvatar botId={bot.id} />
                  <Stack>
                    <Typography>{formatName(bot.name)}</Typography>
                    <Typography variant="caption">{bot.model}</Typography>
                  </Stack>
                </Stack>
                <IconButton onClick={(event) => handleEdit(event, bot)}>
                  <SettingsIcon />
                </IconButton>
              </Stack>
            </MenuItem>
          ))}
        </MenuList>
      </Drawer>
    </>
  );
}
