// @lib-stripe-credit-payments
import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import { httpsCallable } from "firebase/functions";
import { db, functions } from "firebaseApp";
import React, { useEffect, useState } from "react";
import CollectionSelector from "../generic-ui/CollectionSelector";
import { collection, query, where } from "firebase/firestore";
import AccountSelector from "./AccountSelector";


export default function RechargeForm({
  uid,
  rechargeButtonColor="primary", 
  amountButtonColor="secondary",
  headerText="Select recharge amount",
  headerProps,
  renderLoader=() => <Typography>Loading</Typography>,
}) {

  const [processing, setProcessing] = useState(false);
  const [loadingRechargeAmounts, setLoadingRechargeAmounts] = useState(true);
  const [loadError, setLoadError] = useState("");
  const [rechargeError, setRechargeError] = useState("");
  const [rechargeAmounts, setRechargeAmounts] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedGroup, setSelectedGroup] = useState();

  const handleRecharge = async () => {
    try {
      const rechargeAmount = rechargeAmounts[selectedIndex];
      setProcessing(true);
      setRechargeError("");
      const res = await httpsCallable(
        functions,
        "createCheckoutSession"
      )({
        priceId: rechargeAmount.price.id,
        creditId: selectedGroup ? selectedGroup : uid,
      });
      const data = res.data;
      window.location.href = data.sessionUrl;
    } catch (error) {
      console.log(error);
      setRechargeError("An error occurred while trying to recharge. Try again later.");
    }
  };

  useEffect(() => {
    const fetchRechargeAmounts = async () => {
      try {
        setLoadError("");
        const res = await httpsCallable(functions, "listStripeProductsWithPrices")();
        setRechargeAmounts(res.data);
      } catch (error) {
        console.log(error);
        setLoadError("Could not load recharge data. Try again later.");
      } finally {
        setLoadingRechargeAmounts(false);
      }
    };
    fetchRechargeAmounts();
  }, []);

  const renderRechargeAmounts = () => {
    if(!rechargeAmounts) {
      return null;
    }
    if (rechargeAmounts.length === 0) {
      return null;
    }

    return (
      <>
        <AccountSelector 
          uid={uid} 
          selectGroupCallback={(group) => setSelectedGroup(group)}
        />
        <Typography {...headerProps}>{headerText}</Typography>
        {rechargeAmounts
          .sort((x, y) => x.price.unit_amount - y.price.unit_amount)
          .map((rechargeAmount, index) => (
            <Button
              data-testclass="stripe-price-button"
              key={rechargeAmount.id}
              variant={index === selectedIndex ? "contained" : "outlined"}
              color={amountButtonColor}
              onClick={() => setSelectedIndex(index)}
            >
              {rechargeAmount.price.unit_amount / 100}{" "}
              {rechargeAmount.price.currency}
            </Button>
          ))}
        <ActionButton
          data-testid="recharge-button"
          variant="contained"
          processing={processing}
          color={rechargeButtonColor}
          onClick={handleRecharge}
          disabled={selectedIndex === -1}
        >
          {selectedGroup ? "Recharge Group" : "Recharge"}
        </ActionButton>
      </>
    );
  };

  return (
    <Stack spacing={1} py={1}>
      {loadError && <Alert severity="error">{loadError}</Alert>}
      {rechargeError && <Alert severity="error">{rechargeError}</Alert>}
      {loadingRechargeAmounts && (
        renderLoader()
      )}
      {renderRechargeAmounts()}
    </Stack>
  );
}
