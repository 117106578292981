import { Button, Stack, TextField } from "@mui/material";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import AlertMessage from "components/react-lib/generic-ui/AlertMessage";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import React, { useState } from "react";

export default function URLSelector({
  value,
  setValue,
}) {
  
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleUrlChange = (event) => {
    const url = event.target.value;
    setValue({
      name: value?.name || "",
      content: "",
      url,
    });
  }

  const handleNameChange = (event) => {
    const name = event.target.value;
    setValue({
      url: value?.url || "",
      content: value?.content || "",
      name,
    });
  }

  const handleLoadUrlContent = async () => {
    try {
      setError("");
      setSuccess(false);
      if(!value?.url) return;
      setProcessing(true);
      const result = await httpsCallable(functions, "getURLContent")({
        url: value.url,
      });
      const data = result.data;
      if(data.success) {
        setSuccess(true);
        setValue({
          url: value?.url || "",
          name: value?.name || "",
          content: result.data.content,
        })
      } else {
        setError(data.error);
      }
    } catch(error) {
      console.log(error);
      setError("An error occurred.");
    } finally {
      setProcessing(false);
    }
  }

  return (
    <Stack
      spacing={1}
    >
      <TextField
        data-testid="url-name-input"
        label="Name"
        value={value?.name || ""}
        onChange={handleNameChange}
      />
      <TextField
        data-testid="url-input"
        label="URL"
        value={value?.url || ""}
        onChange={handleUrlChange}
      />
      {error && (
        <AlertMessage
          data-testid="url-selector-error"
          severity="error"
        >
          {error}
        </AlertMessage>
      )}
      {success && (
        <AlertMessage
          data-testid="url-selector-success-message"
        >
          URL loaded successfully!
        </AlertMessage>
      )}
      <ActionButton
        variant="contained"
        disabled={!value?.url || value?.content}
        processing={processing}
        onClick={handleLoadUrlContent}
        data-testid="load-url-button"
      >
        Load URL content
      </ActionButton>
    </Stack>
  );

}
