export const colors = {
  black: "#020202",
  white: "#FFFFFC",
  pink: "#FF66F9",
  green: "#CEEA39",
  drakGray: "#1A1A1A",
};

export const backgroundColors = {
  gradient: {
    start: colors.black,
    end: colors.drakGray,
  },
};

export const backgroundGradient = `linear-gradient(-45deg, ${colors.black} 0%, ${colors.drakGray} 74%)`;
