import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Selector from "components/react-lib/generic-ui/Selector";
import URLSelector from "./URLSelector";
import FileUploader from "components/react-lib/generic-ui/FileUploader";
import { useDispatch, useSelector } from "react-redux";
import { addDoc, collection } from "firebase/firestore";
import { db } from "firebaseApp";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import ImageUploader from "components/react-lib/generic-ui/ImageUploader";
import TextAttachment from "components/attachments/TextAttachment";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import { createConversation } from "utils/conversations";

export const URL = { id: "url", label: "URL" };
export const FILE = { id: "file", label: "File" };
export const IMAGE = { id: "image", label: "Image" };
export const TEXT = { id: "text", label: "Text" };

export default function AttachFileButton({
  supportedTypes = [TEXT, URL, FILE, IMAGE],
  buttonProps = {},
}) {
  const dispatch = useDispatch();

  const auth = useAuth();
  const botState = useSelector((state) => state.bot);

  const [attachmentType, setAttachmentType] = useState(supportedTypes[0].id);
  const [modalOpen, setModalOpen] = useState(false);
  const [attachment, setAttachment] = useState(null);

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setAttachment(null);
  }, [attachmentType]);

  useEffect(() => {
    setAttachment(null);
  }, [modalOpen]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleFileUploaded = (file) => {
    setAttachment(file);
  };

  const handleImageUploaded = (image) => {
    setAttachment(image);
  };

  const renderForm = () => {
    switch (attachmentType) {
      case TEXT.id:
        return <TextAttachment value={attachment} setValue={setAttachment} />;
      case URL.id:
        return <URLSelector value={attachment} setValue={setAttachment} />;
      case FILE.id:
        return <FileUploader successCallback={handleFileUploaded} />;
      case IMAGE.id:
        return (
          <ImageUploader
            successCallback={handleImageUploaded}
            bucketName="inputImages"
            preview={true}
          />
        );
    }
    console.warn(`Invalid attachment type ${attachmentType}`);
    return null;
  };

  const handleAttach = async () => {
    try {
      setProcessing(true);
      await addDoc(collection(db, "attachments"), {
        ...attachment,
        type: attachmentType,
        botId: botState.current,
        conversationId: botState.activeConversation.id,
        createdAt: new Date().getTime(),
      });
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  const canAttach = () => {
    switch (attachmentType) {
      case URL.id:
        return Boolean(attachment?.content);
      case FILE.id:
        return Boolean(attachment?.filename);
      case IMAGE.id:
        return Boolean(attachment?.imageUrl);
      case TEXT.id:
        return Boolean(attachment?.content);
    }
    return false;
  };

  const handleOpen = async () => {
    try {
      if(!botState.activeConversation) {
        await createConversation(
          botState.current,
          auth.currentUser.uid,
          dispatch
        );
      }
      setModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Attach</DialogTitle>
        <DialogContent>
          <Stack mt={1} spacing={1}>
            <Selector
              label="Attachment type"
              value={attachmentType}
              setValue={setAttachmentType}
              options={supportedTypes}
              valueField="id"
              labelField="id"
              allowEmpty={false}
            />
            {renderForm()}
          </Stack>
        </DialogContent>
        <DialogActions>
          <ButtonGroup fullWidth>
            <Button variant="contained" color="pink" onClick={handleClose}>
              Cancel
            </Button>
            <ActionButton
              data-testid="finalize-attach-button"
              variant="contained"
              color="black"
              disabled={!canAttach()}
              onClick={handleAttach}
              processing={processing}
            >
              Attach
            </ActionButton>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
      <IconButton
        onClick={handleOpen}
        data-testid="attach-file-button"
        {...buttonProps}
      >
        <AttachFileIcon />
      </IconButton>
    </>
  );
}
