import {
  CircularProgress,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { botActions } from "store/bot";
import { useDispatch, useSelector } from "react-redux";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ConversationMenu from "components/chat/ConversationMenu";
import { colors } from "colors";
import NewConversationButton from "components/chat/NewConversationButton";

export default function ConversationDrawerMenu({
  conversations,
  loading,
  error,
}) {
  const botState = useSelector((state) => state.bot);
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  if (error) {
    return null;
  }

  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (!conversations) {
    return null;
  }

  const conversationDocs = conversations.docs.map((snap) => ({
    id: snap.id,
    ...snap.data(),
  }));

  const handleSelectConversation = (conversation) => {
    dispatch(botActions.setActiveConversation(conversation));
    setMenuOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => setMenuOpen(true)}
        color="white"
        data-testid="main-menu-button"
      >
        <QuestionAnswerIcon />
      </IconButton>
      <Drawer
        data-testid="conversation-menu-drawer"
        open={menuOpen}
        anchor="right"
        onClose={() => setMenuOpen(false)}
        sx={{
          width: "300px",
          "& .MuiDrawer-paper": {
            width: "300px",
            boxSizing: "border-box",
            p: 2,
          },
        }}
      >
        <Stack alignItems="center" justifyContent="center">
          <NewConversationButton
            onCreate={() => setMenuOpen(false)} // Pass callback to close the modal
          />       
        </Stack>
        <MenuList
          sx={{
            overflowY: "auto",
          }}
        >
          {conversationDocs.map((conversation, index) => (
            <ListItem
              key={conversation.id}
              secondaryAction={
                <ConversationMenu
                  previousConversation={
                    index === 0 ? null : conversationDocs[index - 1]
                  }
                  nextConversation={
                    index + 1 >= conversationDocs.length
                      ? null
                      : conversationDocs[index + 1]
                  }
                  conversation={conversation}
                  color={colors.black}
                />
              }
              sx={{
                borderRadius: "4px",
                border:
                  conversation.id === botState.activeConversation?.id
                    ? `1px solid ${colors.black}`
                    : "",
              }}
            >
              <ListItemButton
                onClick={() => handleSelectConversation(conversation)}
              >
                <ListItemText
                  sx={{
                    color: colors.black,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  primary={<Typography>{conversation.subject}</Typography>}
                  secondary={
                    <Typography variant="caption">
                      {new Date(conversation.createdAt).toLocaleString()}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </MenuList>
      </Drawer>
    </>
  );
}
