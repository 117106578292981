// @lib-generic-ui
import { schemas } from "schemas/schemas";
import { getDownloadURL, uploadBytes, ref } from "firebase/storage";
import { storage, db } from "firebaseApp";
import { urlToFile } from "utils/react-lib/generic-ui/imageUtils"
import { v4 as uuidv4 } from 'uuid';

function isMatchingPath(pattern, path) {
  const patternSegments = pattern.split('/');
  const pathSegments = path.split('/');
  for (let i = 0; i < patternSegments.length; i++) {
    if (i % 2 === 0) {
      if (patternSegments[i] !== pathSegments[i]) {
        return false;
      }
    }
  }
  return true;
}

export function getSchema(documentRef, schemaBuildData) {
  const matches = Object.keys(schemas).filter((path) => isMatchingPath(path, documentRef.path));
  if(matches.length === 0) {
    throw Error(`Undefined schema for path ${documentRef.path}`)
  }
  return schemas[matches[0]](schemaBuildData);
}


export const uploadImage = async (imageFile, storagePath) => {
  const res = await uploadImages([imageFile], storagePath);
  return res[0];
}

export const uploadImages = async (imageFiles, storagePath) => {
  const imageData = [];
  for (let i = 0; i < imageFiles.length; i++) {
    let image = imageFiles[i];
    if(typeof(imageFiles[i]) === "string") {
      image = await urlToFile(imageFiles[i]); 
    }
    const imageName = `${uuidv4()}`;
    const imagePath = `${storagePath}/${imageName}`;
    const imageRef = ref(storage, imagePath);
    const snapshot = await uploadBytes(imageRef, image);
    const url = await getDownloadURL(snapshot.ref);
    imageData.push({
      name: imageName,
      url,
      filePath: imagePath,
    });
  }
  return imageData;
};

export function snapshotToArray(snapshot) {
  if(!snapshot) return [];
  var arr = [];
  snapshot.forEach((child) => {
    arr.push({...child.data(), id: child.id});
  });
  return arr;
}

export function snapshotToFirstDoc(snapshot) {
  const arr = snapshotToArray(snapshot);
  if(arr.length > 0) return arr[0];
  return null;
}

export function snapshotToDict(snapshot) {
  if(!snapshot) return {};
  var dict = {};
  snapshot.forEach((child) => {
    dict[child.id] = {...child.data()};
  });
  return dict;
}