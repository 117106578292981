// lib-firebase-auth

export const authErrorMessages = {
  "auth/user-not-found": "No user found with this email. Please sign up.",
  "auth/wrong-password": "Incorrect password. Please try again.",
  "auth/email-already-in-use": "This email is already registered. Try logging in.",
  "auth/invalid-email": "The email address is badly formatted.",
  "auth/weak-password": "The password is too weak. Please provide a stronger password.",
  "auth/operation-not-allowed": "This operation is not allowed. Please contact support.",
  "auth/network-request-failed": "Network error. Please check your internet connection and try again.",
  // Add more error codes and messages as needed
};

export function getFriendlyAuthErrorMessage(error) {
  return authErrorMessages[error.code] || "An unexpected error occurred. Please try again later.";
}