import { Box, IconButton, Stack } from "@mui/material";
import React from "react";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MessageContainer from "components/chat/MessageContainer";
import MarkdownBlock from "components/react-lib/markdown-code/MarkdownBlock";
import { colors } from "colors";
import CopyButton from "components/react-lib/generic-ui/CopyButton";
import ReadMessageAloud from "./MessageAudio";
import MessageAudio from "./MessageAudio";

export default function TextMessage({ message, isLive, inContext, noMenu }) {

  const formatTextBlocks = (message) => {
    if (isLive) return message.join("");
    const messageText = message.content;
    let count = 0;
    // Replace every second occurrence of "```" with "```text"
    const replaceIndexes = [];
    for (let i = 0; i < messageText.length - 3; i++) {
      if (messageText.substring(i, i + 3) === "```") {
        count += 1;
        if (count % 2 === 1) {
          // It's the start of a code block
          if (i + 3 < messageText.length && /\s/.test(messageText[i + 3])) {
            // Replace
            replaceIndexes.push(i);
            i += 3;
          }
        }
      }
    }
    let result = "";
    for (let i = 0; i < messageText.length; i++) {
      if (replaceIndexes.includes(i)) {
        result += "```text";
        i += 3;
      } else {
        result += messageText[i];
      }
    }
    return result;
  };

  const renderBottomContent = () => {
    if (isLive) return null;
    return <CopyButton color="white" />;
  };

  const formattedMessage = formatTextBlocks(message);

  return (
    <MessageContainer
      key={message.id}
      message={message}
      isLive={isLive}
      bottomContent={renderBottomContent()}
      inContext={inContext}
      noMenu={noMenu}
    >
      <MarkdownBlock 
        markdownContent={message.content}
        canCopyCode={true}
        rawTextColor={colors.white}
        rawCopyButtonColor={colors.white}
      />
      {message.image && (
        <Stack
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={message.image}
            alt=""
            style={{
              width: "200px",
              borderRadius: "10px",
            }}
          />
        </Stack>
      )}
      <MessageAudio message={message} />
    </MessageContainer>
  );
}
