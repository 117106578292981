// @lib-generic-ui
import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  position: "relative",
}));

const CircularProgressWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  right: 16,
});

const ActionButton = ({ processing, progressColor="secondary", children, ...props }) => {

  const disabled = Boolean(props.disabled) || processing;

  return (
    <StyledButton {...props} disabled={disabled} >
      {children}
      {processing && (
        <CircularProgressWrapper>
          <CircularProgress size={18} color={progressColor} />
        </CircularProgressWrapper>
      )}
    </StyledButton>
  );
};

export default ActionButton;