import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import React from "react";
import LinkIcon from '@mui/icons-material/Link';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import AbcIcon from '@mui/icons-material/Abc';
import { FILE, URL, IMAGE, TEXT } from "components/attachments/AttachFileButton";
import { colors } from "colors";
import AttachmentMenu from "./AttachmentMenu";

export default function Attachment({
  attachment, 
  nameTextProps, 
  dateTextProps,
}) {

  const renderIcon = () => {
    switch(attachment.type) {
      case URL.id:
        return <LinkIcon />;
      case FILE.id:
        return <DescriptionIcon />;
      case IMAGE.id:
        return <ImageIcon />;
      case TEXT.id:
        return <AbcIcon />;
      default:
        console.warn(`Unkown ${URL.id}`);
        return null;
    }
  }

  const renderText = () => {
    switch(attachment.type) {
      case URL.id:
        return attachment.url;
      case FILE.id:
        return attachment.filename;
      case IMAGE.id:
        return attachment.filename;
      case TEXT.id:
        return attachment.content;
      default:
        console.warn(`Unkown ${attachment.type}`);
        return "";
    }
  }


  return (
    <ListItem
      data-testclass="attachment-item"
      secondaryAction={
        <AttachmentMenu attachment={attachment} />
      }
    >
      <ListItemAvatar>
          <Avatar
            sx={{
              backgroundColor: "transparent",
            }}
          >
            {renderIcon()}
          </Avatar>
        </ListItemAvatar>
      <ListItemText
        primary={renderText()}
        secondary={new Date(attachment.createdAt).toLocaleString()}
        sx={{
          color: colors.white,
          "& .MuiTypography-root": {
            color: colors.white,
          },
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        color="white"
      />
    </ListItem>
  );

}
