import { Stack, TextField } from "@mui/material";
import AlertMessage from "components/react-lib/generic-ui/AlertMessage";
import React, { useState } from "react";

export default function TextAttachment({
  value,
  setValue,
}) {
  
  const [error, setError] = useState("");

  const handleTextChange = (event) => {
    const content = event.target.value;
    setValue({
      name: value?.name || "",
      content,
    });
  }

  const handleNameChange = (event) => {
    const name = event.target.value;
    setValue({
      content: value?.content || "",
      name,
    });
  }

  return (
    <Stack
      spacing={1}
    >
      <TextField
        data-testid="text-name-input"
        label="Name"
        value={value?.name || ""}
        onChange={handleNameChange}
      />
      <TextField
        multiline={true}
        maxRows={3}
        data-testid="text-input"
        label="Text"
        value={value?.content || ""}
        onChange={handleTextChange}
      />
      {error && (
        <AlertMessage
          data-testid="url-selector-error"
          severity="error"
        >
          {error}
        </AlertMessage>
      )}
    </Stack>
  );

}
