import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteDoc, doc } from "firebase/firestore";
import { db, functions } from "firebaseApp";
import { colors } from "colors";
import { useDispatch } from "react-redux";
import { chatActions } from "store/chat";
import ConfirmModal from "components/react-lib/generic-ui/ConfirmModal";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkMessageMenuItem from "./BookmarkMessageMenuItem";
import MessageDetailsModal from "./MessageDetailsModal";
import InfoIcon from "@mui/icons-material/Info";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { COLLECTIONS } from "collections";
import { httpsCallable } from "firebase/functions";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

export default function MessageMenu({ message }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [detailsOpen, setDetailsOpen] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    console.log(message);
    try {
      setProcessing(true);
      const messageRef = doc(
        db,
        COLLECTIONS.bots,
        message.botId,
        COLLECTIONS.conversations,
        message.conversationId,
        COLLECTIONS.messages,
        message.id
      );
      await deleteDoc(messageRef);
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(message.content);
    handleClose();
  };

  const handleSetAsPrompt = () => {
    dispatch(
      chatActions.setBotPrompt({
        botId: message.botId,
        prompt: message.content,
      })
    );
    handleClose();
  };

  const handleCostDetails = () => {
    setDetailsOpen(true);
    handleClose();
  }

  const handleListen = async () => {
    try {
      httpsCallable(functions, "convertMessageToAudio")({
        messageId: message.id,
        conversationId: message.conversationId,
        botId: message.botId,
      });
      handleClose();
    } catch(error) {
      console.log(error);
    }
  }

  return (
    <div>
      <ConfirmModal
        open={deleting}
        setOpen={setDeleting}
        title="Confirm"
        message="Are you sure to delete this message?"
        confirmAction={handleDelete}
        cancelAction={handleClose}
      />
      <MessageDetailsModal
        open={detailsOpen}
        setOpen={setDetailsOpen}
        message={message}
      />
      <IconButton onClick={handleClick} color="white" disabled={processing}>
        {processing ? (
          <CircularProgress size={24} sx={{ color: colors.white }} />
        ) : (
          <MoreVertIcon />
        )}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleCopy}>
          <ListItemIcon>
            <ContentCopyIcon color="black" fontSize="small" />
          </ListItemIcon>
          <ListItemText color="black">Copy</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleListen} disabled={Boolean(message.processingAudio) || Boolean(message.audioUrl)}>
          <ListItemIcon>
            <VolumeUpIcon color="black" fontSize="small" />
          </ListItemIcon>
          <ListItemText color="black">Convert to audio</ListItemText>
        </MenuItem>
        {message.role === "assistant" && (
          <MenuItem onClick={handleCostDetails}>
            <ListItemIcon>
              <AttachMoneyIcon color="black" fontSize="small" />
            </ListItemIcon>
            <ListItemText color="black">Cost Info</ListItemText>
          </MenuItem>
        )}
        <BookmarkMessageMenuItem message={message} callback={handleClose} />
        <MenuItem onClick={handleSetAsPrompt}>
          <ListItemIcon>
            <SmartToyIcon color="black" fontSize="small" />
          </ListItemIcon>
          <ListItemText color="black">Use as prompt</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setDeleting(true)}>
          <ListItemIcon>
            <DeleteIcon color="black" fontSize="small" />
          </ListItemIcon>
          <ListItemText color="black">Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
