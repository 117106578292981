// TypingIndicator.jsx
import React from 'react';
import { styled, keyframes } from '@mui/system';
import Box from '@mui/material/Box';

const bounceAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 
  40% {
    transform: scale(1);
  }
`;

const Dot = styled('div')(({ theme }) => ({
  display: 'inline-block',
  width: 8,
  height: 8,
  margin: '0 4px',
  backgroundColor: "white",
  borderRadius: '50%',
  animation: `${bounceAnimation} 1.4s infinite ease-in-out both`,
}));

const TypingIndicator = () => {
  return (
    <Box display="flex" alignItems="center" my={2}>
      <Dot style={{ animationDelay: "0s" }} />
      <Dot style={{ animationDelay: "0.2s" }} />
      <Dot style={{ animationDelay: "0.4s" }} />
    </Box>
  );
};

export default TypingIndicator;