import React, { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from "@mui/material";
import ConfirmModal from "components/react-lib/generic-ui/ConfirmModal";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "firebaseApp";

export default function AttachmentMenu({attachment}) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadText = async () => {
    const link = document.createElement("a");
    link.href = attachment.rawTextFileUrl;
    link.setAttribute("download", "transcript.txt" || "download");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "attachments", attachment.id));
    } catch(error) {
      console.log(error);
    }
  }

  const handleDeleteClick = () => {
    setConfirmingDelete(true);
    handleClose();
  }

  return (
    <>
      <ConfirmModal
        open={confirmingDelete}
        setOpen={setConfirmingDelete}
        confirmAction={handleDelete}
        title="Delete Attachment"
        message="Are you sure to delete this attachment?"
      />
      <IconButton
        onClick={handleClick}
        color="white"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleDownloadText}>Download text</MenuItem> */}
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </>
  )
}
