import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  model: "",
  systemPrompt: "",
  avatar: "",
  contextSize: 0,
  stream: true,
  maxTokens: "",
  id: null,
  fileId: null,
}

const createBotSlice = createSlice({
  name: "createBotSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    initializeFromBot(state, action) {
      const {
        name,
        model,
        systemPrompt,
        avatar,
        contextSize,
        id,
        fileId,
      } = action.payload;
      state.name = name;
      state.model = model;
      state.systemPrompt = systemPrompt;
      state.avatar = avatar;
      state.contextSize = contextSize;
      state.id = id;
      state.fileId = fileId;
    },
    updateField(state, action) {
      const {field, value} = action.payload;
      if(field === "maxTokens") {
        if (value === "" || ((/^\d+$/.test(value) && parseInt(value, 10) >= 0))) {
          state[field] = value;
        }
      } else if(field === "contextSize") {
        if (value === "" || ((/^\d+$/.test(value) && parseInt(value, 10) >= 0) && parseInt(value, 10) <= 5)) {
          state[field] = value;
        }
      } else {
        state[field] = value;
      }
    },
  }
});

export const createBotActions = createBotSlice.actions;

export default createBotSlice.reducer;