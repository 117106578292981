import React, { useState, useRef } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import { CircularProgress, IconButton } from '@mui/material';
import { colors } from 'colors';
import TwoIconButton from '../generic-ui/TwoIconButton';
import ClearIcon from '@mui/icons-material/Clear';

const RecordButton = ({
  audioURL,
  setAudioURL,
  startRecordingCallback,
  endRecordingCallback,
  clearRecordingCallback,
}) => {

  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = async () => {
    if(startRecordingCallback) {
      startRecordingCallback();
    }
    setAudioURL("");
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };
    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      const audioURL = URL.createObjectURL(audioBlob);
      setAudioURL(audioURL);
      audioChunksRef.current = [];
      if(endRecordingCallback) {
        endRecordingCallback(audioURL);
      }
    };
    mediaRecorderRef.current.start();
    setRecording(true);
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
  };

  const handleClearRecording = () => {
    setRecording(false);
    setAudioURL("");
    if(clearRecordingCallback) { 
      clearRecordingCallback();
    }
  }

  return (
    <div>
      <div style={{ position: "relative", display: "inline-flex" }}>
      {audioURL ? (
        <TwoIconButton
          color="white"
          secondaryIcon={<ClearIcon sx={{color: colors.white}}/>}
          onClick={handleClearRecording}
        >
          <MicIcon />
        </TwoIconButton>
      ) : (
        <IconButton color="white" onClick={recording ? stopRecording : startRecording}>
          <MicIcon />
        </IconButton>
      )}
      {recording && (
        <CircularProgress
          onClick={stopRecording}
          size={48}
          style={{
            color: colors.pink,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -24,
            marginLeft: -24,
          }}
        />
      )}
    </div>
      {/* <button onClick={recording ? stopRecording : startRecording}>
        {recording ? 'Stop Recording' : 'Start Recording'}
      </button> */}
      {/* {audioURL && (
        <div>
          <audio src={audioURL} controls />
          <a href={audioURL} download="recording.wav">
            Download Recording
          </a>
        </div>
      )} */}
    </div>
  );
};

export default RecordButton;