export const seeds = [
  "Cleo",
  "Oscar",
  "Mimi",
  "Toby",
  "Zoe",
  "Sugar",
  "Spooky",
  "Sophie",
  "Bubba",
  "Leo",
  "Gracie",
  "Coco",
  "Bear",
  "Milo",
  "Cookie",
  "Muffin",
  "Trouble",
  "Shadow",
  "Mittens",
  "Jaster",
  "Buster",
  "Princess",
  "Jasmine",
  "Callie",
  "Bailey",
  "Buddy",
  "Missy",
  "Kitty",
  "Tigger",
  "Midnight",
  "Max",
  "Snickers",
];