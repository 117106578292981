// @lib-generic-ui
import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import Loader from "components/react-lib/generic-ui/Loader";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

export default function CollectionSelector({
  q,
  displayField,
  sortingFunction,
  label,
  selectElementCallback,
  noSelectLabel = "",
  emptyMessage = "No elements yet.",
  selectProps = {},
  loadMessage = "Loading",
  loadProps = {},
}) {

  const DEFAULT = {
    [displayField]: noSelectLabel,
    id: noSelectLabel,
  };

  const [selectedElement, setSelectedElement] = useState(DEFAULT.id);
  const [docsSnap, loading, error] = useCollection(q);

  if (error) {
    console.log(error);
  }

  if (loading) {
    return <Loader progressProps={loadProps} message={loadMessage} />;
  }

  if (!docsSnap || docsSnap.empty) {
    return <Typography>{emptyMessage}</Typography>;
  }

  const handleOnChange = (event) => {
    const element = event.target.value;
    setSelectedElement(element);
    const doc = element === noSelectLabel ? null : docs.filter((doc) => doc.id === element)[0]; 
    selectElementCallback(element === noSelectLabel ? null : element, doc);
  }

  let docs = docsSnap.docs;
  if(sortingFunction) {
    docs = docs.sort(sortingFunction);
  }
  docs = docs.map((doc) => ({id: doc.id, ...doc.data()}));
  docs = [DEFAULT, ...docs];

  return (
    <FormControl fullWidth>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        id="select"
        {...selectProps}
        value={selectedElement}
        label={label}
        onChange={handleOnChange}
      >
        {docs.map((doc) => (
          <MenuItem key={doc.id} value={doc.id}>{doc[displayField]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
