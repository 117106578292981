import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uid: null,
  claims: {},
}

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    logout() {
      return initialState;
    },
    login(state, action) {
      state.uid = action.payload.uid;
    },
    setClaims(state, action) {
      state.claims = action.payload;
    },
  }
});

export const userActions = userSlice.actions;

export default userSlice.reducer;