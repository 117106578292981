import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import backgroundAnimation from "lottie/background.json";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import success from "lottie/success.json";
import failure from "lottie/failure.json";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import { colors } from "colors";
import Loader from "components/react-lib/generic-ui/Loader";

export default function 
PaymentRedirectView() {

  const params = useParams();
  const [processing, setProcessing] = useState(true);
  let [searchParams] = useSearchParams();

  const paymentWasSuccessful = params.status === "success";
  const navigate = useNavigate();

  useEffect(() => {
    if(processing) return;
    const timer = setTimeout(() => {
      navigate("/");
    }, paymentWasSuccessful ? 2000 : 5000);
    return () => clearTimeout(timer);
  }, [processing]);

  const processPayment = async () => {
    try {
      if (paymentWasSuccessful) {        
        await httpsCallable(functions, "processPaymentSuccessful")({sessionId: searchParams.sessionId});
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    processPayment();
  }, []);

  const renderAnimation = () => {
    return (
      <Lottie
        animationData={paymentWasSuccessful ? success : failure}
        loop={false}
        style={{
          width: "150px",
        }}
      />
    );
  };

  const renderMessage = () => {
    if (paymentWasSuccessful) {
      return <Typography color={colors.white}>Payment successful, redirecting...</Typography>;
    }
    return (
      <Stack>
        <Typography color={colors.white}>Couldn't process the payment, redirecting...</Typography>
      </Stack>
    );
  };

  const renderContent = () => {
    if (processing) {
      return <Loader message="Processing payment, please keep the page open" textProps={{color: colors.white}} />;
    }
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
      >
        {renderAnimation()}
        {renderMessage()}
      </Stack>
    );
  };

  return (
    <Stack
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundColor: colors.black,
        position: "relative",
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Lottie
        animationData={backgroundAnimation}
        loop={true}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
        }}
      />
      {renderContent()}
    </Stack>
  );
}
