import { Button, Stack } from "@mui/material";
import YouTube from "components/dev/YouTube";
import PdfUploader from "components/react-lib/generic-ui/FileUploader";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import React from "react";

export default function TestView() {
  
  const testGemini = async () => {
    try {
      const res = await httpsCallable(functions, "generateTextWithGemini")();
      console.log(res);

    } catch(error) {
      console.log(error);
    }
  }

  return (
    <Stack>
      <PdfUploader />
      <YouTube />

      <Button
        onClick={testGemini}
      >
        Test GEMINI
      </Button>
    </Stack>
  );

}
