import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

export default function MessageDetailsModal({ open, setOpen, message }) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Message Details</DialogTitle>
      <DialogContent>
        <Table
          size="small"
        >
          <TableBody>
            {message.chargeData?.inputCost && (
              <TableRow>
                <TableCell align="left">
                  <Typography fontWeight="bold">Input Cost</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{message.chargeData.inputCost.toFixed(6)}</Typography>
                </TableCell>
              </TableRow>
            )}
            {message.chargeData?.inputTokens && (
              <TableRow>
                <TableCell align="left">
                  <Typography fontWeight="bold">Input Tokens</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{message.chargeData.inputTokens}</Typography>
                </TableCell>
              </TableRow>
            )}
            {message.chargeData?.outputCost && (
              <TableRow>
                <TableCell align="left">
                  <Typography fontWeight="bold">Output Cost</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{message.chargeData.outputCost.toFixed(6)}</Typography>
                </TableCell>
              </TableRow>
            )}
            {message.chargeData?.outputTokens && (
              <TableRow>
                <TableCell align="left">
                  <Typography fontWeight="bold">Output Tokens</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{message.chargeData.outputTokens}</Typography>
                </TableCell>
              </TableRow>
            )}
            {message.chargeData?.audioTranscriptionCost !== undefined && (
              <TableRow>
                <TableCell align="left">
                  <Typography fontWeight="bold">Audio Transcription Cost</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{message.chargeData.audioTranscriptionCost.toFixed(6)}</Typography>
                </TableCell>
              </TableRow>
            )}
            {message.chargeData?.totalCost && (
              <TableRow>
                <TableCell align="left">
                  <Typography fontWeight="bold">Total Cost</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{message.chargeData.totalCost.toFixed(6)}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
