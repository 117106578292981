import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import CopyUid from "components/groups/CopyUid";
import DeleteAccountButton from "components/react-lib/auth/DeleteAccountButton";
import Loader from "components/react-lib/generic-ui/Loader";
import EditDocumentForm from "components/react-lib/generic-ui/firestore/EditDocumentForm";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import { doc } from "firebase/firestore";
import { db } from "firebaseApp";
import React from "react";

export default function EditProfileModal({ open, setOpen }) {
  const auth = useAuth();

  const renderContent = () => {
    if (!auth.currentUser) {
      return <Loader message="Loading profile data" />;
    }
    return (
      <Stack
        spacing={1}
      >
        <CopyUid />
        <EditDocumentForm
          documentRef={doc(db, "userPublicProfiles", auth.currentUser.uid)}
          schemaBuildData={{
            bucket: "userAvatars",
          }}
          saveCallback={handleClose}
        />
        <DeleteAccountButton
          color="secondary"
          variant="contained"
        />
      </Stack>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" data-testid="profile-modal">
      <DialogTitle>Profile</DialogTitle>
      <DialogContent>
        <Stack my={1}>{renderContent()}</Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" fullWidth onClick={handleClose} data-testid="profile-modal-close-button">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
