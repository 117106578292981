import React from "react";
import MessageContainer from "components/chat/MessageContainer";
import { Stack } from "@mui/material";
import DownloadImageButton from "components/chat/DownloadImageButton";

export default function ImageMessage({ message }) {

  const renderBottomContent = () => {
    return <DownloadImageButton imageUrl={message.image}/>
  }

  return (
    <MessageContainer message={message}  bottomContent={renderBottomContent()}>
      <Stack
        alignItems="center"
        justfiycontent="center"
      >
        <img src={message.image} alt="" width="40%" />
      </Stack>
    </MessageContainer>
  );
}
