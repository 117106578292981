import { Stack, Typography } from "@mui/material";
import CopyButton from "components/react-lib/generic-ui/CopyButton";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import React from "react";

export default function CopyUid() {
  const auth = useAuth();

  if (!auth.currentUser) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography>
        <b>User id:</b> {auth.currentUser.uid}
      </Typography>
      <CopyButton text={auth.currentUser.uid} />
    </Stack>
  );
}
