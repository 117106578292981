import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import ActionButton from "./ActionButton";
import { getFileUploadUrl } from "utils/react-lib/generic-ui/storageUtils";

const FileUploader = ({ successCallback }) => {

  const [file, setFile] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    event.target.value = null;
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    setProcessing(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        getFileUploadUrl(),
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      if (responseData.error) {
        console.log(responseData.error);
      } else {
        setFile(null);
        successCallback(responseData);
      }
    } catch (error) {
      console.error("Upload error:", error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Stack
      spacing={1}
    >
      {file && (
          <Typography textAlign="center" variant="caption" data-testid="text-file-uploader-file-name">
            {file.name}
          </Typography>
        )}
      <Stack direction="row" spacing={1}>
        <Button color="primary" component="label" variant="outlined">
          Select File
          <input
            data-testid="text-file-uploader-file-selector-input"
            type="file"
            onChange={handleFileChange}
            hidden
            accept=".txt,.pdf,.docx,.mp3" // updated to accept more file types
          />
        </Button>
        <ActionButton
          data-testid="text-file-uploader-upload-button"
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!file || processing}
          processing={processing}
        >
          {processing ? "Upload File" : "Upload File"}
        </ActionButton>
      </Stack>
    </Stack>
  );
};

export default FileUploader;
