// @lib-firebase-auth
import React, { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "firebaseApp";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Create a context for the authentication state
const AuthContext = createContext();

// Use this hook to access the auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

// Define the AuthProvider
export const AuthProvider = ({ children, defaulPrivateProfile = {}, defaulPublicProfile = {} }) => {
  
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // const initializeProfile = async (user) => {
  //   if(!user) return;
  //   console.log(user.uid);
  //   try {
  //     // Create public profile
  //     const publicProfileRef = doc(db, "userPublicProfiles", user.uid);
  //     const publicProfileSnap = await getDoc(publicProfileRef);
  //     if(!publicProfileSnap.exists()) {
  //       await setDoc(publicProfileRef, {
  //         uid: user.uid,
  //         username: user.uid,
  //         lastUpdated: new Date().getTime(),
  //         avatarUrl: "",
  //         defaultChargeAccount: user.uid,
  //         ...defaulPublicProfile,
  //       })
  //     }
  //     // Create private profile
  //     const privateProfileRef = doc(db, "userPrivateProfiles", user.uid);
  //     const privateProfileSnap = await getDoc(privateProfileRef);
  //     if(!privateProfileSnap.exists()) {
  //       await setDoc(privateProfileRef, {
  //         uid: user.uid,
  //         lastUpdated: new Date().getTime(),
  //         email: user.email,
  //         displayName: user.displayName || "",
  //         ...defaulPrivateProfile,
  //       })
  //     }
  //   } catch(error) {
  //     console.log(error);
  //   }
  // }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      //initializeProfile(user);
      setCurrentUser(user);
      setLoading(false);
    });
    // Cleanup subscription on unmount
    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};