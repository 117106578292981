import React from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { colors } from "colors";

export default function MessageAudio({ message }) {

  const hasAudioUrl = Boolean(message.audioUrl);
  const processing = Boolean(message.processingAudio);

  if (!processing && !hasAudioUrl) return null;

  if (hasAudioUrl) {
    return (
      <audio
        style={{
          width: "100%",
          paddingTop: "10px",
        }}
        src={message.audioUrl}
        controls
      />
    );
  }

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <CircularProgress color="white" size={24} />
      <Typography sx={{color: colors.white}}>Processing audio...</Typography>
    </Stack>
  );
}
