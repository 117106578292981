import { colors } from "colors";
import Attachment from "components/attachments/Attachment";
import Loader from "components/react-lib/generic-ui/Loader";
import { collection, query, where } from "firebase/firestore";
import { db } from "firebaseApp";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";

export default function ConversationAttachments({attachments}) {


  // const q = query(
  //   collection(db, "attachments"),
  //   where("conversationId", "==", conversationId),
  // );
  
  // const [attachmentsSnap, loading, error] = useCollection(conversationId ? q : null);
  
  // if(error) {
  //   console.log(error);
  //   return null;
  // }

  // if(loading) {
  //   return (
  //     <Loader message="Loading attachments" />
  //   );
  // }

  return (
    <>
      {attachments.map((attachment) => (
        <Attachment 
          key={attachment.id}
          nameTextProps={{color: colors.white, variant: "caption"}}
          dateTextProps={{color: colors.white, variant: "caption"}}
          attachment={attachment}
        />
      ))}
    </>
  );

}
