import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { colors } from "colors";
import FileItem from "components/files/FileItem";
import { useSelector } from "react-redux";
import ConversationAttachments from "components/chat/ConversationAttachments";
import { snapshotToArray } from "utils/react-lib/generic-ui/firebaseUtils";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, query, where } from "firebase/firestore";
import { db } from "firebaseApp";

export default function BotFiles({ bot }) {

  const botState = useSelector((state) => state.bot);

  const conversationId = botState.activeConversation?.id;

  const q = conversationId ? query(
    collection(db, "attachments"),
    where("conversationId", "==", conversationId),
  ) : null;
  
  const [attachmentsSnap, loading, error] = useCollection(q);

  if(error) {
    console.log(error);
  }

  const attachments = snapshotToArray(attachmentsSnap);

  if(!bot || !botState.activeConversation || (!bot.fileId && attachments.length === 0)) return null;

  return (
    <Accordion
      sx={{
        backgroundColor: colors.drakGray,
      }}
      data-testid="bot-files-accordion"
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: colors.white}}/>}>
        <Typography
          variant="caption"
          fontWeight="bold"
          sx={{
            color: colors.white,
          }}
        >
          Files & Attachments
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {bot.fileId && (
          <FileItem 
            fileId={bot.fileId} 
            nameTextProps={{color: colors.white, variant: "caption"}}
            dateTextProps={{color: colors.white, variant: "caption"}}
            fileMenuProps={{color: "white"}}
          />
        )}
        <ConversationAttachments
          attachments={attachments}
          //conversationId={botState.activeConversation.id}
        />
      </AccordionDetails>
    </Accordion>
  );
}
