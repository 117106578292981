// @lib-generic-ui
import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DefaultFieldEditor from "./DefaultFieldEditor";
import { updateDoc } from "firebase/firestore";
import ActionButton from "../ActionButton";
import { useDocument } from "react-firebase-hooks/firestore";
import Loader from "../Loader";
import _ from "lodash";
import { getSchema } from "utils/react-lib/generic-ui/firebaseUtils";


export default function EditDocumentForm({
  documentRef,
  schemaBuildData = {},
  saveCallback = () => {},
}) {

  const schema = getSchema(documentRef, schemaBuildData);
  const fieldOrder = schema._fieldOrder;
  
  const [saving, setSaving] = useState();
  const [state, setState] = useState();
  const [initialState, setInitialState] = useState();

  const [docSnapshot, loading, error] = useDocument(documentRef);

  useEffect(() => {
    let doc = {
      id: documentRef.id,
    };
    if(docSnapshot) {
      doc = {
        ...doc,
        ...docSnapshot.data(),
      };
    }
    fieldOrder.forEach((field) => {
      if(!doc.hasOwnProperty(field)) {
        doc[field] = schema[field].defaultValue;
      }
    });
    console.log(doc);
    setState(doc);
    setInitialState(doc);
  }, [docSnapshot]);

  if(error) {
    console.log(error);
  }

  if(loading || !state) {
    return (
      <Loader message="Loading" />
    );
  }

  const setFieldValue = (field) => (value) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const renderFieldEditor = (field, index) => {
    const editor = schema[field].editor;
    if (editor) {
      return editor(state[field], setFieldValue);
    }
    return (
      <DefaultFieldEditor
        key={`${field}-${index}`}
        value={state[field]}
        setValue={setFieldValue(field)}
        field={field}
        fieldSchema={schema[field]}
        processing={saving}
      />
    );
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      await updateDoc(documentRef, state);
      saveCallback();
    } catch(error) {
      console.log(error);
    } finally {
      setSaving(false);
    }
  }

  const canSave = () => {
    if(_.isEqual(state, initialState)) {
      return false;
    }
    for(const field of fieldOrder) {
      if(!schema[field].isValid(state[field])) {
        return false;
      }
    }
    return true;
  }

  return (
    <Stack spacing={2}>
      {fieldOrder.map((field, index) => (
        renderFieldEditor(field, index)
      ))}
      <ActionButton 
        data-testclass="edit-document-form-save-button"
        processing={saving} 
        variant="contained" 
        onClick={handleSave} 
        disabled={!canSave()}
      >
        Save
      </ActionButton>
    </Stack>
  );
}
