import LabelledOutline from "components/react-lib/generic-ui/LabelledOutline";
import Loader from "components/react-lib/generic-ui/Loader";
import { collection, query, where } from "firebase/firestore";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import UserGroup from "./UserGroup";
import { db } from "firebaseApp";
import { Stack, Typography } from "@mui/material";

export default function MyUserGroups({ uid }) {
  const q = query(
    collection(db, "userGroups"),
    where("members", "array-contains", uid)
  );

  const [groupsSnap, loading, error] = useCollection(q);

  if (error) {
    console.log(error);
  }

  if (loading) {
    return <Loader message="Loading groups" />;
  }

  if (!groupsSnap || groupsSnap.empty) {
    return (
      <Stack alignItems="center" justifyContent="center">
        <Typography variant="caption" textAlign="center">
          No user groups.
        </Typography>
      </Stack>
    );
  }

  console.log(groupsSnap.docs);

  return (
    <Stack spacing={1} my={1}>
      {groupsSnap.docs
        .sort((x, y) => x.createdAt - y.createdAt)
        .map((snap) => {
          const group = { id: snap.id, ...snap.data() };
          return (
            <LabelledOutline key={group.id} label={group.name}>
              <UserGroup group={group} />
            </LabelledOutline>
          );
        })}
    </Stack>
  );
}
