import React from "react";
import UserInGroup from "./UserInGroup";
import { Stack } from "@mui/material";
import AddUserForm from "./AddUserForm";

export default function UserGroup({ group }) {

  return (
    <Stack
      spacing={1}
    >
      {group.members.map((userId) => (
        <UserInGroup key={`${group.id}-${userId}`} userId={userId} />
      ))}
      <AddUserForm 
        group={group}
      />
    </Stack>
  )
}
