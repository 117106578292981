import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import CreateGroupForm from "./CreateGroupForm";
import MyUserGroups from "./MyUserGroups";


export default function GroupsModal({ open, setOpen }) {

  const auth = useAuth();
  const uid = auth.currentUser?.uid;

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateGroup = async () => {
    
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Groups</DialogTitle>
      <DialogContent sx={{ overflowY: "auto" }}>
        <CreateGroupForm uid={uid} />
        <MyUserGroups uid={uid} />
      </DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth>
          <Button color="black" onClick={handleClose} >
            Close
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
