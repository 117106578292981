// @lib-generic-ui
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Stack } from "@mui/material";
import {
  query,
  orderBy,
  limit,
  onSnapshot,
  startAfter,
  getDocs,
} from "firebase/firestore";

const ScrollableCollection = ({
  collectionQuery,
  containerStyle,
  renderElement,
  renderEmptyElement = () => null,
  renderPrefixElements = () => null,
  renderSuffixElements = () => null,
  initialSlice = 10,
  loadSlice = 10,
}) => {

  const [elems, setElems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const elemsListRef = useRef(null);
  const lastVisible = useRef(null);
  const isLoading = useRef(false);
  const previousLastElemRef = useRef(null);

  useEffect(() => {

    setElems([]);
    setHasMore(true);
    lastVisible.current = null;
    isLoading.current = false;
    previousLastElemRef.current = null;
    
    const q = query(
      collectionQuery,
      orderBy("createdAt", "desc"),
      limit(initialSlice)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const elems = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      if (elems.length > 0) {
        setElems(elems.reverse());
        lastVisible.current = snapshot.docs[snapshot.docs.length - 1];
      } else {
        setHasMore(false);
        setElems([]);
        lastVisible.current = null;
        previousLastElemRef.current = null;
      }
    });

    return () => unsubscribe();
  }, [collectionQuery]);

  const loadMore = async () => {
    if (isLoading.current || !hasMore) return;
    isLoading.current = true;
    const q = query(
      collectionQuery,
      orderBy("createdAt", "desc"),
      startAfter(lastVisible.current),
      limit(loadSlice)
    );
    const nextElemsSnapshot = await getDocs(q);
    const nextElems = nextElemsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    if (nextElems.length > 0) {
      setElems((prevElems) => [...nextElems.reverse(), ...prevElems]);
      lastVisible.current =
        nextElemsSnapshot.docs[nextElemsSnapshot.docs.length - 1];
    } else {
      setHasMore(false);
    }
    isLoading.current = false;
  };

  const handleScroll = () => {
    if (elemsListRef.current.scrollTop <= 0) {
      loadMore();
    }
  };

  const scrollToBottom = useCallback(() => {
    const list = elemsListRef.current;
    list.scrollTop = list.scrollHeight;
  }, []);

  useEffect(() => {
    if (elems.length > 0) {
      const lastElem = elems[elems.length - 1];
      const prevLastElem = previousLastElemRef.current;
      if (lastElem && (!prevLastElem || lastElem.id !== prevLastElem.id)) {
        scrollToBottom();
        previousLastElemRef.current = lastElem;
      }
    }
  }, [elems, scrollToBottom]);

  return (
    <Stack
      spacing={1}
      sx={{
        ...containerStyle,
        overflowY: "auto",
        overflowX: "none",
      }}
      ref={elemsListRef}
      onWheel={handleScroll}
    >
        {elems.length === 0 && renderEmptyElement()}
        {renderPrefixElements()}
        {elems.map((elem, index) => (
          <React.Fragment
            key={`element-${index}`}
          >
            {renderElement(elem, index, elems.length - index - 1)}
          </React.Fragment>
        ))}
        {renderSuffixElements()}
    </Stack>
  );
};

export default ScrollableCollection;
