// export const firebaseConfig = {
//   apiKey: "AIzaSyAGpdwDcPdLmNgfBjxFAJ0NAnHigHaEFR0",
//   authDomain: "app-template-69f27.firebaseapp.com",
//   projectId: "app-template-69f27",
//   storageBucket: "app-template-69f27.appspot.com",
//   messagingSenderId: "485950583239",
//   appId: "1:485950583239:web:77fe3442d79ba21f7f29e2",
//   measurementId: "G-5NJRXVGCZF"
// };

export const firebaseConfig = {
  apiKey: "AIzaSyBgKsSQ4aEl03lWqnIz_Vt0WpeCfusqO3Q",
  authDomain: "cheapgptchat.firebaseapp.com",
  projectId: "cheapgptchat",
  storageBucket: "cheapgptchat.firebasestorage.app",
  messagingSenderId: "347669405496",
  appId: "1:347669405496:web:187e598152942c48d62bcd",
  measurementId: "G-WH03W3PTEY"
};