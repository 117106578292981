import React from "react";
import TextMessage from "components/chat/TextMessage";
import ImageMessage from "components/chat/ImageMessage";
import TypingMessage from "./TypingMessage";
import AudioMessage from "components/chat/AudioMessage";

export default function Message({ message, inContext, noMenu = false }) {

  if(message.cancelled) return null;

  if (message.type === "text") {
    return <TextMessage message={message} inContext={inContext} noMenu={noMenu} />;
  }

  if (message.type === "image") {
    return <ImageMessage message={message} noMenu={noMenu} />;
  }

  if(message.type === "pending") {
    return <TypingMessage message={message} inContext={false} />;
  }
  
  if(message.type === "audio") {
    return <AudioMessage message={message} inContext={inContext} noMenu={noMenu} />
  }

  console.log("Unknown message type", message.type);

  return null;
}
