import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import UserAvatar from "components/react-lib/generic-ui/UserAvatar";
import { colors } from "colors";
import MessageMenu from "./MessageMenu";
import BotAvatar from "components/bot/BotAvatar";

export default function MessageContainer({
  message,
  children,
  isLive,
  inContext,
  noMenu,
}) {

  const chatState = useSelector((state) => state.chat);
  const processing = Boolean(chatState.botProcessing[message.botId]);

  const liveBotId = useSelector((state) => state.chat.liveBotId);
  const isAi = message.role === "assistant";

  const renderAvatar = () => {
    if (isLive) {
      return <BotAvatar botId={liveBotId} />;
    }
    if (isAi) {
      return <BotAvatar botId={message.botId} />;
    }
    return <UserAvatar />;
  };

  return (
    <div
      data-testclass="chat-message-container"
      style={{  
        backgroundColor: colors.drakGray,
        zIndex: 1,
        paddingTop: 20,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 8,
        position: "relative",
        width: "100%",
        boxSizing: "border-box",
        border: (!processing && inContext ? `1px solid ${colors.white}80` : `1px solid ${colors.black}`),
        maxWidth: "748px",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
      >
        {renderAvatar()}
        {!noMenu && (
          <MessageMenu message={message} />
        )}
      </Stack>
      {children}
    </div>
  );
}
