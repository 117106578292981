import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "firebaseApp";
import React from "react";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { COLLECTIONS } from "collections";

export default function BookmarkMessageMenuItem({ message, callback }) {

  const toogleBookmarked = async () => {
    const newBookmarked = message.bookmarked ? false : true;
    await updateDoc(doc(db, COLLECTIONS.bots, message.botId, COLLECTIONS.conversations, message.conversationId, COLLECTIONS.messages, message.id), {
      bookmarked: newBookmarked,
    });
    const bookmarkRef = doc(db, COLLECTIONS.users, message.uid, COLLECTIONS.bookmarks, message.id);
    if(newBookmarked) {
      await setDoc(bookmarkRef, {
        ...message, 
        bookmarkedAt: new Date().getTime()
      });
    } else {
      await deleteDoc(bookmarkRef);
    }
    callback();
  };

  return (
    <MenuItem onClick={toogleBookmarked}>
      <ListItemIcon>
        {message.bookmarked ? (
          <BookmarkIcon color="black" fontSize="small" />
        ) : (
          <BookmarkBorderIcon color="black" fontSize="small" />
        )}
      </ListItemIcon>
      <ListItemText color="black">Save</ListItemText>
    </MenuItem>
  );
}
