// @lib-generic-ui
import { IconButton } from "@mui/material";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function CopyButton({ text, onCopy, ...props }) {

  const handleOnCopy = () => {
    if(onCopy) {
      onCopy();
    }
  }

  return (
    <CopyToClipboard 
      text={text}
      onCopy={handleOnCopy}
    >
      <IconButton
        {...props}
      >
        <ContentCopyIcon />
      </IconButton>
    </CopyToClipboard>
  );

}
