import { COLLECTIONS } from "collections";
import { addDoc, collection } from "firebase/firestore";
import { db } from "firebaseApp";
import { botActions } from "store/bot";

export async function createConversation(botId, uid, dispatch) {
  try {
    const conversationData = {
      botId,
      uid,
      createdAt: new Date().getTime(),
      subject: "New conversation",
      isDefault: false,
    };
    const conversationRef = await addDoc(
      collection(db, COLLECTIONS.bots, botId, COLLECTIONS.conversations),
      conversationData,
    );
    const conversation = {
      id: conversationRef.id,
        ...conversationData,
    };
    dispatch(
      botActions.setActiveConversation(conversation)
    );
    return conversation;
  } catch(error) {
    console.log(error);
    return null;
  }
}