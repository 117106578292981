import React, { useState } from "react";
import ConfirmModal from "../generic-ui/ConfirmModal";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import { signOut } from "firebase/auth";
import AlertMessage from "../generic-ui/AlertMessage";
import { auth } from "firebaseApp";
import ActionButton from "../generic-ui/ActionButton";

export default function DeleteAccountButton({
  ...props
}) {

  const [error, setError] = useState("");
  const [confirming, setConfirming] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDeleteAccount = async () => {
    try {
      setError("");
      setDeleting(true);
      const res = await httpsCallable(functions, "deleteUser")();
      if(res.error) {
        console.log(res);
        setError(res.error);
      } else {
        await signOut(auth);
      }
    } catch(error) {
      console.log(error);
      setError("An error occured while trying to delete the account.");
    } finally {
      setDeleting(false);
    }
  }

  return (
    <>
      <ConfirmModal
        open={confirming}
        setOpen={setConfirming}
        title="Account Deletion"
        message="Are you sure to delete this account? This action is irreversible and cannot be undone."
        confirmAction={handleDeleteAccount}
      />
      {error && (
        <AlertMessage
          severity="error"
        >
          {error}
        </AlertMessage>
      )}
      <ActionButton
        {...props}
        onClick={() => setConfirming(true)}
        data-testid="delete-account-button"
        processing={deleting}
      >
        Delete Account
      </ActionButton>
    </>
  );

}
