import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import Loader from "components/react-lib/generic-ui/Loader";
import { doc } from "firebase/firestore";
import { db } from "firebaseApp";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import DescriptionIcon from '@mui/icons-material/Description';
import { colors } from "colors";
import FileMenu from "./FileMenu";

export default function FileItem({ 
  fileId,
  nameTextProps = {},
  dateTextProps = {},
  fileMenuProps = {},
}) {

  const [fileData, loading, error] = useDocumentData(fileId ? doc(db, "files", fileId) : null);

  if(error) {
    console.log(error);
  }

  if(loading) {
    return (
      <Loader message="Loading file data" />
    );
  }

  if(!fileData) {
    return (
      <Typography
        variant="caption"
        textAlign="center"
      >
        No data file.
      </Typography>
    );
  }

  return (
    <ListItem
      data-testclass="file-item"
      secondaryAction={
        <FileMenu 
          file={{id: fileId, ...fileData}}
          {...fileMenuProps}
        />
      }
    >
      <ListItemAvatar>
        <Avatar>
          <DescriptionIcon />
        </Avatar>
        </ListItemAvatar>
      <ListItemText
        primary={<Typography {...nameTextProps}>{fileData.filename}</Typography>}
        secondary={
          <Typography
            {...dateTextProps}
          >
            {new Date(fileData.createdAt).toLocaleString()}
          </Typography>
        }
        color="white"
      />
    </ListItem>
  );

}
