import { Button, Stack } from "@mui/material";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import AlertMessage from "components/react-lib/generic-ui/AlertMessage";
import TextInput from "components/react-lib/generic-ui/TextInput";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "firebaseApp";
import React, { useState } from "react";

export default function AddUserForm({ group }) {
  const [isAdding, setIsAdding] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [error, setError] = useState("");

  const searchUserByField = async (field) => {
    const querySnap = await getDocs(
      query(collection(db, "userPublicProfiles"), where(field, "==", userInfo))
    );
    if (querySnap.empty) return null;
    const userSnap = querySnap.docs[0];
    return { id: userSnap.id, ...userSnap.data() };
  };

  const searchUserByFields = async (fields) => {
    for (const field of fields) {
      const user = await searchUserByField(field);
      if (user) return user;
    }
    return null;
  };

  const handleCreateGroup = async () => {
    try {
      setError("");
      setProcessing(true);
      const user = await searchUserByFields(["uid", "username"]);
      if (!user) {
        setError("Couldn't find user.");
      } else {
        if(group.members.includes(user.id)) {
          setError("The user is already in the group");
        } else {
          await updateDoc(doc(db, "userGroups", group.id), {
            members: [...group.members, user.id],
          });
          setUserInfo("");
          setIsAdding(false);
        }
      }
    } catch (error) {
      console.log(error);
      setError("Couldn't add user. Try again later.");
    } finally {
      setProcessing(false);
    }
  };

  const render = () => {
    if (isAdding) {
      return (
        <Stack spacing={1}>
          {error && <AlertMessage severity="error">{error}</AlertMessage>}
          <TextInput
            label="User query"
            value={userInfo}
            setValue={setUserInfo}
          />
          <ActionButton
            variant="outlined"
            onClick={handleCreateGroup}
            processing={processing}
            disabled={!userInfo}
          >
            Add User
          </ActionButton>
        </Stack>
      );
    }
    return (
      <Button variant="outlined" onClick={() => setIsAdding(true)}>
        Add New User
      </Button>
    );
  };

  return <Stack py={1}>{render()}</Stack>;
}
