// @lib-stripe-credit-payments
import { doc } from "firebase/firestore";
import { db, functions } from "firebaseApp";
import React, { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { CircularProgress, Skeleton, Tooltip, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import useIsMobile from "hooks/react-lib/generic-ui/useIsMobile";

export default function CreditsIndicator({
  chargeAccountId,
  textProps,
  checkPaymentsOnLoad = true,
  currencySymbol = "",
  negativeCreditsColor = "",
}) {
  
  const isMobile = useIsMobile();

  const [credits, loading, error] = useDocumentData(
    doc(db, "credits", chargeAccountId)
  );

  if (error) {
    console.log(error);
  }

  const [amount, setAmount] = useState(null);

  useEffect(() => {
    setAmount(credits ? credits.amount : 0);
  }, [credits]);

  const checkBalance = async () => {
    try {
      if (checkPaymentsOnLoad) {
        await httpsCallable(functions, "checkUnpaidPayments")();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkBalance();
  }, []);

  if (error) {
    console.log(error);
  }

  if (loading || amount === null) {
    return <CircularProgress size={24} />;
  }

  function formatFloat(num) {
    return Math.floor(num * 100) / 100;
  }

  return (
    <Tooltip title={amount}>
      <Typography
        {...textProps}
        color={
          amount < 0 && negativeCreditsColor
            ? negativeCreditsColor
            : textProps.color
        }
        fontSize={isMobile ? 12 : 18}
      >
        {currencySymbol}
        {formatFloat(amount)}
      </Typography>
    </Tooltip>
  );
}
