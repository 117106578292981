import { configureStore } from "@reduxjs/toolkit";
import chatReduer from "./chat";
import userReducer from "store/user";
import botReducer from "store/bot";
import createBotReducer from "store/createBot";

const store = configureStore({
  reducer: {
    chat: chatReduer,
    user: userReducer,
    bot: botReducer,
    createBot: createBotReducer,
  }
});

export default store;