// @lib-stripe-credit-payments
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "firebaseApp";
import React, { useEffect, useState } from "react";
import Loader from "../generic-ui/Loader";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function AccountSelector({ 
  uid, 
  selectAccountCallback, 
  selectGroupCallback, 
  processing, 
  defaultAccountId = null,
}) {

  const PERSONAL_ACCOUNT = "Personal Account";

  const [loading, setLoading] = useState(true);
  const [accountsData, setAccountsData] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [accounts, setAccounts] = useState();

  const loadData = async () => {
    try {
      let accounts = [];
      const groups = await getDocs(
        query(
          collection(db, "userGroups"),
          where("members", "array-contains", uid)
        )
      );
      const accountsData = {};
      for (const group of groups.docs) {
        const groupBalance = await getDoc(doc(db, "credits", group.id));
        accountsData[group.data().name] = {
          id: group.id,
          isGroup: true,
          amount: groupBalance && groupBalance.data() ? groupBalance.data().amount : 0,
        }
        accounts.push(group.data().name);
      }
      const userCredits = await getDoc(doc(db, "credits", uid));
      accountsData[PERSONAL_ACCOUNT] = {
        id: uid,
        isGroup: false,
        amount: userCredits && userCredits.data() ? userCredits.data().amount : 0,
      }
      setAccountsData(accountsData);
      accounts = accounts.sort((x, y) => (x < y ? -1 : 1));
      accounts = [PERSONAL_ACCOUNT, ...accounts];
      setAccounts(accounts);
      setSelectedAccount(accounts[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if(defaultAccountId && accounts) {
      Object.keys(accountsData).forEach((name) => {
        const account = accountsData[name];
        if(account.id === defaultAccountId) {
          setSelectedAccount(name);
        }
      })
    }
  }, [defaultAccountId, accounts]);

  useEffect(() => {
    loadData();
  }, [uid]);

  const handleChange = (event) => {
    const account = event.target.value;
    const accountData = accountsData[account];
    if(selectAccountCallback) {
      selectAccountCallback(accountData.id);
    }
    if(selectGroupCallback) {
      selectGroupCallback(accountData.isGroup ? accountData.id : null);
    }
    setSelectedAccount(account);
  }

  if (loading || !accounts || !accountsData || !selectedAccount) {
    return <Loader message="Loading accounts" />;
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Account</InputLabel>
      <Select
        label="Account"
        value={selectedAccount}
        onChange={handleChange}
        disabled={processing}
      >
        {accounts.map((account) => (
          <MenuItem key={account} value={account}>
            {accountsData[account].isGroup ? "Group Account: " : ""} {account} (${accountsData[account].amount.toFixed(2)})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
