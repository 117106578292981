import React, {createElement } from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

const TwoIconButton = ({
  children,
  secondaryIcon,
  ...props
}) => {
 
  // Create a new element with extended sx
  const StyledSecondaryIcon = createElement(secondaryIcon.type, {
    ...secondaryIcon.props,
    sx: {
      ...secondaryIcon.props?.sx,
      width: "10px",
    },
  });

  return (
    <IconButton {...props}>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        badgeContent={
          StyledSecondaryIcon
        }
      >
        {children}
      </Badge>
    </IconButton>
  );
};

export default TwoIconButton;