
import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator, setDoc, doc } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { firebaseConfig } from 'firebaseConfig';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

const isDevMode = window.location.hostname === "localhost";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app, firebaseConfig.storageBucket);

if(isDevMode) {
  // Setup emulator usage
  connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectStorageEmulator(storage, "localhost", 9199);
}

export {
  auth,
  db,
  functions,
  storage,
};
