import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import React from "react";
import { doc } from "firebase/firestore";
import { db } from "firebaseApp";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { signOut } from "firebase/auth";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import RechargeForm from "components/react-lib/stripe-credit-payments/RechargeForm";
import LabelledOutline from "components/react-lib/generic-ui/LabelledOutline";
import { colors } from "colors";
import Loader from "../react-lib/generic-ui/Loader";
import CopyUid from "../groups/CopyUid";
import ChargeAccountSelector from "components/react-lib/stripe-credit-payments/ChargeAccountSelector";

export default function AccountModal({ open, setOpen }) {

  const auth = useAuth();
  const uid = auth.currentUser?.uid;

  const [profile, loadingProfile, profileError] = useDocumentData(
    doc(db, "userPublicProfiles", uid)
  );

  if (profileError) {
    console.log(profileError);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignOut = async () => {
    await signOut(auth);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth data-testid="accounts-modal">
      <DialogTitle>Credit Accounts</DialogTitle>
      <DialogContent sx={{ overflowY: "auto" }}>
        <Stack spacing={2}>
          {loadingProfile || !profile ? (
            <Loader message="Loding profile" />
          ) : (
            <Stack spacing={2} pt={1}>
              <LabelledOutline
                label="Charge account"
                rawLabelColor={colors.black}
                rawBorderColor={colors.black}
              >
                <ChargeAccountSelector
                  uid={uid}
                />
              </LabelledOutline>
              <LabelledOutline
                label={"Add credits to an account"}
                rawLabelColor={colors.black}
                rawBorderColor={colors.black}
              >
                <RechargeForm
                  uid={uid}
                  headerText=""
                  amountButtonColor="pink"
                  rechargeButtonColor="black"
                  renderLoader={() => (
                    <Loader
                      message="Loading"
                      progressProps={{
                        color: "black",
                      }}
                    />
                  )}
                />
              </LabelledOutline>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <ButtonGroup fullWidth>
          <Button color="black" onClick={handleClose} data-testid="accounts-modal-close-button">
            Close
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
}
