import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { getFunctions, httpsCallable } from "firebase/functions";
import ActionButton from './ActionButton';
import AlertMessage from './AlertMessage';

const ImageUploader = ({
  successCallback, 
  errorCallback,
  bucketName,
  buttonLabel = "Select Image",
  preview = false,
}) => {

  const [file, setFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("");

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    event.target.value = null;
  };

  useEffect(() => {
    if(file) {
      uploadImage(file);
    }
  }, [file]);

  const uploadImage = async (file) => {
    if(!file) return;
    try {
      setError("");
      const reader = new FileReader();
      reader.onloadend = async () => {
        setProcessing(true);
        const base64data = reader.result;
        const functions = getFunctions();
        const uploadBase64Image = httpsCallable(functions, "uploadBase64Image");
        try {
          const response = await uploadBase64Image({
            fileName: file.name,
            base64Image: base64data,
            bucketName,
          });
          const responseData = response.data;
          console.log(responseData);
          if(responseData.error) {
            console.log(responseData.error);
            setError(responseData.error);
            if(errorCallback) {
              errorCallback(responseData);
            }
          } else {
            if(successCallback) {
              setUrl(response.data.imageUrl);
              successCallback(response.data);        
            }
          }
        } catch (error) {
          console.error("Upload error:", error);
          setError("An error occured while uploading the image.");
        } finally {
          setProcessing(false);
        }
      };

      reader.readAsDataURL(file);
    } catch(error) {
      console.log(error);
      setError("An error occured.");
    }
  }


  return (
    <Stack
      spacing={1}
    >
      {error && <AlertMessage severity="error">{error}</AlertMessage>}
      {preview && url && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <img
            data-testid="image-uploader-preview"
            src={url} 
            alt="preview" 
            style={{
              width: "100px",
              borderRadius: "4px",
            }}
          />
        </Stack>
      )}
      <ActionButton 
        variant="contained" 
        color="primary" 
        processing={processing}
        component="label"
        data-testclass="image-uploader-button"
      >
        {buttonLabel}
        <input 
          type="file" 
          onChange={handleFileChange} 
          hidden 
          accept="image/*" 
          data-testclass="image-uploader-input"
        />
      </ActionButton>
    </Stack>
  );
};

export default ImageUploader;