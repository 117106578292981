// @lib-generic-ui
import React from "react";
import {
  CircularProgress,
  Stack, Typography,
} from "@mui/material";

export default function Loader({ message = "Loading", spacing=2, textProps, progressProps }) {

  return (
    <Stack
      spacing={spacing}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress 
        {...progressProps}
      />
      <Typography
        textAlign="center"
        variant="caption"
        {...textProps}
      >
        {message}
      </Typography>
    </Stack>
  );

}