import { COLLECTIONS } from "collections";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import { useAuth } from "context/react-lib/auth/AuthProvider";
import { addDoc, collection } from "firebase/firestore";
import { db } from "firebaseApp";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { botActions } from "store/bot";

export default function NewConversationButton({onCreate, ...props}) {
  
  const auth = useAuth();
  const dispatch = useDispatch();
  const uid = auth.currentUser?.uid;

  const [processing, setProcessing] = useState(false);
  const botState = useSelector((state) => state.bot);

  const handleCreateConversation = async () => {
    try {
      const conversationData = {
        botId: botState.current,
        uid,
        createdAt: new Date().getTime(),
        subject: "New conversation",
        isDefault: false,
      };
      const conversationRef = await addDoc(
        collection(db, COLLECTIONS.bots, botState.current, COLLECTIONS.conversations),
        conversationData,
      );
      const conversation = {
        id: conversationRef.id,
          ...conversationData,
      }
      dispatch(botActions.setActiveConversation(conversation));
      if(onCreate) {
        onCreate(conversation);
      }
    } catch(error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  }

  return (
    <ActionButton
      fullWidth
      variant="outlined"
      processing={processing}
      onClick={handleCreateConversation}
      size="small"
      data-testid="new-conversation-button"
      {...props}
    >
      New Converation
    </ActionButton>
  );

}
