// @lib-markdown-code
import { Box, IconButton } from "@mui/material";
import React from "react";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function MarkdownBlock({ 
  markdownContent, 
  rawTextColor = "black", 
  rawCopyButtonColor = "black", 
  canCopyCode = true 
}) {
  return (
    <div
      style={{
        color: rawTextColor,
        lineHeight: 1.5,
        fontFamily: '"Montserrat", sans-serif',
        overflowX: "auto",
      }}
    >
      <Markdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          code(props) {
            const { children, className, node, ...rest } = props;
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <SyntaxHighlighter
                  {...rest}
                  children={String(children).replace(/\n$/, "")}
                  language={match[1]}
                  PreTag="div"
                  style={vscDarkPlus}
                />
                {canCopyCode && (
                  <CopyToClipboard text={String(children)}>
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    >
                      <ContentCopyIcon 
                        sx={{
                          color: rawCopyButtonColor,
                        }}
                      />
                    </IconButton>
                  </CopyToClipboard>
                )}
              </Box>
            ) : (
              <code
                className="inline-code"
                style={{
                  backgroundColor: "rgb(30, 30, 30)",
                  padding: 2,
                  borderRadius: 4,
                }}
              >
                {children}
              </code>
            );
          },
        }}
      >
        {markdownContent}
      </Markdown>
    </div>
  );
}
