import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

export default function Selector({ label, value, setValue, options, valueField, labelField, allowEmpty=true}) {

  const realOptions = allowEmpty ? [{ id: "", valueField: "", labelField: "" }, ...options] : [...options];

  return (
    <FormControl>
      <InputLabel>
        {label}
      </InputLabel>
      <Select
        data-testclass="generic-selector"
        value={value}
        label={label}
        onChange={(event) => setValue(event.target.value)}
      >
        {realOptions.map((option) => (
          <MenuItem
            data-testclass="generic-selector-menu-item"
            key={option.id}
            value={option[valueField]}
          >
            {option[labelField]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

}
