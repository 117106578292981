import { Avatar, Stack } from "@mui/material";
import React from "react";
import { seeds } from "utils/avatars";
import LabelledOutline from "components/react-lib/generic-ui/LabelledOutline";


export function AvatarButton({ seed, selected, onClick }) {
  const handleClick = () => {
    if (onClick) {
      onClick(seed);
    }
  };
  return (
    <Avatar
      data-testclass="avatar-button"
      onClick={handleClick}
      src={`https://api.dicebear.com/7.x/bottts/svg?seed=${seed}`}
      sx={{
        border: selected ? "2px solid #070F2B" : "2px solid white",
      }}
    />
  );
}

export default function AvatarSelector({ avatar, setAvatar }) {
  return (
    <LabelledOutline label="Avatar">
      <Stack
        direction="row"
        flexWrap="wrap"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        {seeds.map((seed) => (
          <AvatarButton
            key={seed}
            seed={seed}
            selected={seed === avatar}
            onClick={(seed) => setAvatar(seed)}
          />
        ))}
      </Stack>
    </LabelledOutline>
  );
}
