import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  bots: [],
  current: null,
  idMap: {},
  contextSize: 1,
  activeConversation: null,
}

const botSlice = createSlice({
  name: "botSlice",
  initialState: initialState,
  reducers: {
    reset() {
      return initialState;
    },
    initialize(state, action) {
      const bots = action.payload;
      state.bots = bots;
      state.loading = false;
      state.current = bots[0].id;
      state.idMap = {};
      for(let i = 0; i < bots.length; i++) {
        state.idMap[bots[i].id] = bots[i];
      }
    },
    selectBot(state, action) {
      const botId = action.payload;
      state.current = botId;
      state.contextSize = state.idMap[botId].contextSize;
    },
    setContextSize(state, action) {
      state.contextSize = action.payload;
    },
    resetContextSize(state) {
      state.contextSize = state.idMap[state.current].contextSize;
    },
    setActiveConversation(state, action) {
      state.activeConversation = action.payload;
    }
  }
});

export const botActions = botSlice.actions;

export default botSlice.reducer;