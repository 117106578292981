import { Button, Stack } from "@mui/material";
import ActionButton from "components/react-lib/generic-ui/ActionButton";
import AlertMessage from "components/react-lib/generic-ui/AlertMessage";
import TextInput from "components/react-lib/generic-ui/TextInput";
import { addDoc, collection } from "firebase/firestore";
import { db } from "firebaseApp";
import React, { useState } from "react";

export default function CreateGroupForm({uid}) {

  const [isCreating, setIsCreating] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [error, setError] = useState("");

  const handleCreateGroup = async () => {
    try {
      setError("");
      setProcessing(true);
      await addDoc(collection(db, "userGroups"), {
        owner: uid,
        members: [uid],
        createdAt: new Date().getTime(),
        name: groupName,
      });
      setGroupName("");
      setIsCreating(false);
    } catch (error) {
      console.log(error);
      setError("Couldn't create group. Try again later.");
    } finally {
      setProcessing(false);
    }
  };

  const render = () => {
    if (isCreating) {
      return (
        <Stack
          spacing={1}
        >
          {error && <AlertMessage severity="error">{error}</AlertMessage>}
          <TextInput label="Group name" value={groupName} setValue={setGroupName} />
          <ActionButton
            variant="outlined"
            onClick={handleCreateGroup}
            processing={processing}
            disabled={!groupName}
          >
            Create Group
          </ActionButton>
        </Stack>
      );
    }
    return (
      <Button variant="outlined" onClick={() => setIsCreating(true)}>
        New Group
      </Button>
    );
  };

  return <Stack py={1}>{render()}</Stack>;
}
