// lib-firebase-auth
import { Button } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "firebaseApp";
import React from "react";

export default function LogoutButton({...props}) {

  const logout = async () => {
    await signOut(auth);
  }

  return (
    <Button
      {...props}
      data-testid="logout-button"
      onClick={logout}
    >
      Logout
    </Button>
  );

}
