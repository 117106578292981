import { Avatar, Card, CardHeader, IconButton, Skeleton } from "@mui/material";
import { doc } from "firebase/firestore";
import { db } from "firebaseApp";
import React from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import UserInGroupMenu from "./UserInGroupMenu";

export default function UserInGroup({ userId }) {

  const [userData, loading, error] = useDocumentDataOnce(
    doc(db, "userPublicProfiles", userId)
  );

  if (error) {
    console.log(error);
  }

  const renderField = (field) => {
    if(loading) {
      return (
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
      );
    }
    console.log("JERE");
    return userData[field];
  }

  console.log(userData);

  return (
    <Card>
      <CardHeader
        avatar={
          loading ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Avatar alt={userData.username} src={userData.avatarUrl} />
          )
        }
        title={renderField("username")}
        //subheader={renderField("email")}
        action={<UserInGroupMenu />}
      />
    </Card>
  );
}
